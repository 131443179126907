import { graphql } from "gatsby";
import React, { useRef } from "react";
import { strToTitleCase } from "../functions";
import styled from "styled-components";
import { useRenderChart } from "../components/charts/chart";
import SEO from "../components/seo";
import { device } from "../components/breakpoint";
const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  ${device.sm`
    margin-top: 55px;
  `}
  a {
    text-decoration: underline;
    text-decoration-color: #000;
  }
`;

const AirbnbCoHost = ({ data, pageContext }) => {
  const { shortTermRentalSetAverages } = data;
  const { name, shortTermRentalGroup } = shortTermRentalSetAverages;
  const { charts } = shortTermRentalGroup;
  const {
    person_capacity,
    rating_cleanliness,
    price_rate,
    photos,
    beds,
    bathrooms,
  } = shortTermRentalGroup;
  const cityTitle = strToTitleCase(name);
  // console.log(data);
  return (
    <div>
      <SEO
        title={`Airbnb Co-host service ${cityTitle} - Estimated $${(
          price_rate * 30
        ).toFixed(0)} In Revenue Per Month`}
        description={`Looking for an Airbnb Co-host that actually delivers results? Average of $${(
          price_rate * 30
        ).toFixed(2)} in Revenue with Airbnb`}
      />

      <a
        target="_blank"
        href={`https://buy.stripe.com/6oE3ec2KJ9NldFe289`}
        className="md:hidden"
      >
        <div
          className="z-10 fixed bottom-0 w-full grid items-center  justify-center text-white bg-black  p-4 
        hover:bg-white  hover:text-black
        "
        >
          <span className="pt-2">👉 Book A Consult Now! </span>
        </div>
      </a>

      <Container className="">
        <div className="pr-4 pl-4  md:p-0">
          <div className="col-span-8">
            <h1 className="text-3xl font-bold text-center">
              Airbnb Co-host service {cityTitle} - Estimated $
              {(price_rate * 30).toFixed(0)} In Revenue Per Month - Partner With
              The Best
            </h1>

            <p className="text-xl">
              Our Co-host features include the following
            </p>

            <ol className="mt-4 text-lg">
              <li className="mt-4">
                1. We will help with turnover and management for your AirBnB
                property in in {cityTitle}
              </li>
              <li className="mt-4">
                2. We help you price your Airbnb listing relative to other
                listings with the same bedroom and bathroom configuration.
                <ul className="mt-4">
                  <li>
                    <h2 className="text-xl text-center font-medium">
                      How to price an Airbnb rental listing based on bedroom and
                      bathrooms in {cityTitle}
                    </h2>
                  </li>
                  <li className="grid grid-flow-row-dense h-96 mt-4">
                    <ChartItem chart={charts[6]} city={name} />
                  </li>
                </ul>
              </li>
              <li className="mt-4">
                3. We will optimize the Amenities on your property which are
                unique to every rental market
                {cityTitle}{" "}
                <a target="_blank" href={`/${name.split(" ").join("-")}`}>
                  here
                </a>
                .
                <ul className="mt-4">
                  <li>
                    <h2 className="text-xl text-center font-medium">
                      The most profitable amenities in {cityTitle} for Airbnb
                      listings
                    </h2>
                  </li>
                  <li className="grid grid-flow-row-dense h-96 mt-4">
                    <ChartItem chart={charts[2]} city={name} />
                  </li>
                </ul>
              </li>

              <li className="mt-4">
                4. We will optimize your Airbnb rental listing description. We
                use data from hundreds of listings to aggregate the best
                keywords for maximum bookings and traffic for your listing. This
                will improve your search ranking on Airbnb.
                <ul className="mt-4">
                  <li>
                    <h2 className="text-xl text-center font-medium">
                      Airbnb SEO and keywords in {cityTitle}
                    </h2>
                  </li>
                  <li className="grid grid-flow-row-dense h-96 mt-4">
                    <ChartItem chart={charts[7]} city={name} />
                  </li>
                </ul>
              </li>

              <div className="flex mt-4">
                <button
                  as="a"
                  href="https://buy.stripe.com/4gwbLffTO7vHb4Q7bd"
                  target="_blank"
                  appearance="primary"
                  variant="solid"
                  style={{ textDecoration: "none" }}
                >
                  Book A Consultation Now
                </button>
                <div className="ml-4">
                  <button
                    appearance="subtle"
                    as="a"
                    href="mailto:consulting@rentalguideai.com"
                    colorScheme="blue"
                    variant="outline"
                  >
                    Email Us
                  </button>
                </div>
              </div>
            </ol>
          </div>
        </div>
      </Container>
    </div>
  );
};

const ChartItem = ({ chart, city }) => {
  const ref = useRef();
  useRenderChart(chart, ref, city);
  return <div ref={ref} />;
};

export default AirbnbCoHost;

export const pageQuery = graphql`
  query ($cityName: String!) {
    shortTermRentalSetAverages(name: { eq: $cityName }) {
      shortTermRentalGroup {
        beds
        bathrooms
        access
        additional_house_rules
        amenities
        weekly_price_factor
        url
        transit
        total_price
        star_rating
        satisfaction_guest
        room_type
        room_and_property_type
        review_score
        review_count
        response_time
        response_rate
        rating_value
        rating_location
        rating_communication
        rating_cleanliness
        rating_checkin
        rating_accuracy
        price_rate_type
        price_rate
        photos
        person_capacity
        notes
        neighborhood_overview
        name
        monthly_price_factor
        longitude
        interaction
        latitude
        description
        city
        charts
      }
      name
    }
  }
`;
